import React from "react";
import ReactMarkdown from "react-markdown";
import { HashLink as Link } from "react-router-hash-link";
// import { Link, Element , Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import "./StickyJourneyOverview.css";
import removeEmojis from "./removeEmoji";

interface Props {
  markdown: any;
  activeHeader: string;
}

export class StickyJourneyOverview extends React.Component<Props> {
  private getCircles({ level, children }: any) {
    const { activeHeader } = this.props;
    const text = children[0].props.value;
    const cleanedText = removeEmojis(text).trim();
    const isSummary =
      cleanedText.toLowerCase().includes("summary") ||
      cleanedText.toLowerCase().includes("next step") ||
      cleanedText.toLowerCase().includes("nästa steg");
    const id = cleanedText
      .split(" ")
      .join("-")
      .toLowerCase();
    const isActive = id === activeHeader;

    if (level === 2) {
      return (
        <Link
          to={`#${id}`}
          scroll={el => {
            (window as any)
              .$(".container")
              .stop()
              .animate(
                {
                  scrollTop: el.offsetTop
                },
                1000,
                "swing"
              );
            // el.scrollIntoView({ behavior: "smooth", block: "start" });
          }}
        >
          {/* <span>{text.split(" ")[0]}</span> */}
          <span className={`circle ${isSummary && "summary-arrow"} ${isActive && "active"}`} />
        </Link>
      );
    }
    return <span style={{ display: "none" }} />;
  }

  render() {
    const { markdown } = this.props;
    return (
      <div className="StickyJourneyOverview">
        <div className="line" />
        <ReactMarkdown
          allowedTypes={["heading", "text"]}
          renderers={{ heading: this.getCircles.bind(this) }}
          source={markdown}
        />
      </div>
    );
  }
}
