import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import "./Splash.css";
// import backgroundImage from './splash-background.png'
// import logoImage from '/logo.png';

interface Props extends RouteComponentProps<any> {}

interface State {
  markdown: string;
}

class Splash extends React.Component<Props, State> {
  state: State = {
    markdown: ""
  };

  componentDidMount() {
    fetch("journey.md")
      .then(response => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.text();
      })
      .then(markdown => {
        this.setState({ markdown });
      });
  }

  getHeading(props: any) {
    const { level, children } = props;
    if (props.level === 1) {
      return React.createElement(`h${level}`, {}, children);
    } else {
      return <span />;
    }
  }

  render() {
    return (
      <div className="Splash">
        <img className="logo" src="/logo.png" />

        <div className="journey-title">
          <ReactMarkdown
            allowedTypes={["heading", "text"]}
            renderers={{ heading: this.getHeading }}
            source={this.state.markdown}
          />
        </div>

        <Link to="/journey">
          <div className="button">EXPLORE</div>
        </Link>

        <footer>
          <i>by</i>
          <h4>DARESAY</h4>
        </footer>
      </div>
    );
  }
}

export default withRouter<Props>(Splash);
