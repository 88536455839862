import React from "react";
import { StickyContainer, Sticky } from "react-sticky";
import "./ActivityHeader.css";
import needs from "./needs.svg";
import insight from "./insight.svg";
import challenge from "./challenge.svg";
import removeEmojis from "./removeEmoji";

interface Props {
  level: number;
  children: any;
}

export default (onSticky: (obj: any) => void) =>
  class ActivityHeader extends React.Component<Props> {
    private getStickyHeader({ style, distanceFromTop, isSticky, wasSticky, id }: any) {
      const { level, children } = this.props;
      const text = children[0].props.value;
      const cleanedText = removeEmojis(text).trim();
      const isSummary =
        cleanedText.toLowerCase().includes("summary") ||
        cleanedText.toLowerCase().includes("next step") ||
        cleanedText.toLowerCase().includes("nästa steg");
      const sizeClassName = isSticky ? "small" : "big";

      // console.log(id, onSticky);

      onSticky({ id, distanceFromTop, isSticky });

      return (
        <div className={`sticky-header ${sizeClassName} ${isSummary ? "summary" : ""}`} style={style}>
          <h2>{cleanedText}</h2>
        </div>
      );
    }

    private getNormalHeader() {
      const { level, children } = this.props;
      const text = children[0].props.value;
      const isH1 = level === 1;
      let image;

      switch (text.toLowerCase()) {
        case "insight":
        case "insights":
        case "insikt":
        case "insikter":
          image = insight;
          break;

        case "challenge":
        case "challenges":
        case "utmaning":
        case "utmaningar":
          image = challenge;
          break;

        case "need":
        case "needs":
        case "behov":
          image = needs;
          break;
      }

      return (
        !isH1 && (
          <span className="subheading">
            {image && <img src={image} />}
            {React.createElement(`h${level}`, {}, children)}
          </span>
        )
      );
    }

    render() {
      const { level, children } = this.props;
      const shouldBeSticky = level === 2;
      const text = children[0].props.value;
      const cleanedText = removeEmojis(text).trim();
      const id = cleanedText
        .split(" ")
        .join("-")
        .toLowerCase();

      return (
        <div id={id} className={`ActivityHeader ${shouldBeSticky ? "sticky" : ""}`}>
          {shouldBeSticky ? (
            <Sticky relative={true} topOffset={50}>
              {({ style, distanceFromTop, isSticky, wasSticky }) =>
                this.getStickyHeader({ style, distanceFromTop, isSticky, id, wasSticky })
              }
            </Sticky>
          ) : (
            this.getNormalHeader()
          )}
        </div>
      );
    }
  };
