import React from "react";
import ReactPlayer from "react-player";

interface Props {
  alt: string;
  src: string;
  children: any;
}

export class ImageAndVideo extends React.Component<Props> {
  private renderVideo() {
    const { children, alt, src } = this.props;
    
    return (
      <ReactPlayer
        url={src}
        width="100%"
        height="100%" 
        controls
        config={{
          youtube: {
            playerVars: {
              rel: 0, modestbranding: 1, autohide: 1, showinfo: 0, controls: 0
            }
          }
        }}
      />
      // <video preload="auto" controls data-alt={alt}>
      //   { src.includes('.mp4') &&
      //     <source src={src} type="video/mp4" />
      //   }
      //   { src.includes('.webm') &&
      //     <source src={src} type="video/webm" />
      //   }
      // </video>
    );
  }

  render() {
    const { children, alt, src } = this.props;

    const isVideo = src.includes(".mp4") || src.includes("youtube");
    return isVideo ? this.renderVideo() : <img src={src} alt={alt} />;
  }
}
