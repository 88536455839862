import React from "react";
import ReactMarkdown from "react-markdown";
import { debounce } from "lodash";
import { StickyContainer, Sticky } from "react-sticky";
import getActivityHeader from "./ActivityHeader";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { StickyJourneyOverview } from "./StickyJourneyOverview";
import { ImageAndVideo } from "./ImageAndVideo";
import "./Journey.css";

const SharedContext = React.createContext("activity");

interface State {
  markdown: string;
  activityID: string;
}

interface Props extends RouteComponentProps<any> {}

const avtivityObj: any = {};

class Journey extends React.Component<Props, State> {
  state: State = {
    markdown: "",
    activityID: ""
  };

  constructor(props: Props) {
    super(props);
    this.setNewHeader = debounce(this.setNewHeader);
  }

  componentDidMount() {
    fetch("journey.md")
      .then(response => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.text();
      })
      .then(markdown => {
        this.setState({ markdown });
      });

    // setInterval(() => {
    //   this.setState({
    //     activityID: Math.random()
    //   })
    // }, 1000)
  }

  private onScroll({ id, distanceFromTop, isSticky }: any) {
    avtivityObj[id] = {
      isSticky,
      distanceFromTop
    };

    const stickys = Object.keys(avtivityObj).filter(key => avtivityObj[key].isSticky);

    let activeHeader = "";
    let closestToTop: string | undefined;
    // console.log(stickys);

    stickys.forEach(id => {
      if (typeof closestToTop === "undefined") {
        closestToTop = avtivityObj[id].distanceFromTop;
        activeHeader = id;
      } else if (avtivityObj[id].distanceFromTop > closestToTop) {
        closestToTop = avtivityObj[id].distanceFromTop;
        activeHeader = id;
      }
    });

    this.setNewHeader(activeHeader);
  }

  private setNewHeader(activityID: string) {
    if (this.state.activityID !== activityID) {
      this.setState({ activityID });
      // Ugly!
      (document.querySelector(".container") as any).dispatchEvent(new Event("scroll"));
    }
  }

  render() {
    // console.log(this.state.activityID);

    return (
      <div className="Journey">
        <StickyJourneyOverview activeHeader={this.state.activityID} markdown={this.state.markdown} />
        <StickyContainer className="container relative markdown-body">
          <ReactMarkdown
            escapeHtml={false}
            renderers={{ heading: getActivityHeader(this.onScroll.bind(this)), image: ImageAndVideo }}
            source={this.state.markdown}
          />
          <footer>
            <h2>DARESAY</h2>
          </footer>
        </StickyContainer>
      </div>
    );
  }
}

export default withRouter<Props>(Journey);
