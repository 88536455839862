import React from "react";
import { HashRouter as Router, Route, Link } from "react-router-dom";
import "./App.css";
// import "./markdown.css";
import Splash from "./components/Splash";
import Journey from "./components/Journey";

function App() {
  return (
    <Router>
      <div className="app">
        <Route exact path="/" component={Splash} />
        <Route path="/journey" component={Journey} />
      </div>
    </Router>
  );
}

export default App;
